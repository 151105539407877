import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';
import "./Forms.css";

export const ProjectFinancialsForm = ({ values, setValues }) => {
  // useEffect(() => {
  //   const savedValues = JSON.parse(localStorage.getItem("projectData")) || {};
  //   setValues(savedValues);
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("projectData", JSON.stringify(values));
  // }, [values]);

  const handleFocus = (event) => {
    const { name, value } = event.target;
    if (value) {
      // Split on first space and keep only the number part
      const numericValue = value.split(' ')[0];
      setValues(prevValues => ({
        ...prevValues,
        [name]: numericValue
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value) {
      let formattedValue = value;
      switch (name) {
        case 'cashChange':
          if (!value.includes('€/ $')) formattedValue = `${value} €/ $`;
          break;
        case 'operationCost':
          if (!value.includes('k€/year')) formattedValue = `${value} k€/year`;
          break;
        case 'discountRate':
        case 'inflationRate':
        case 'priceCorrectionFactor':
          if (!value.includes('%')) formattedValue = `${value} %`;
          break;
        case 'projectLifetime':
          if (!value.includes('years')) formattedValue = `${value} years`;
          break;
        default:
          break;
      }
      setValues({
        ...values,
        [name]: formattedValue
      });

      // Update parent component with the formatted value
      // if (setFinancialData) {
      //   setFinancialData(prevData => ({
      //     ...prevData,
      //     [name]: formattedValue
      //   }));
      // }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Only allow numbers and decimal points in the input
    if (value === '' || /^-?\d*\.?\d*$/.test(value)) {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Project Financials</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cash Change *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/ $"
                  name="cashChange"
                  value={values.cashChange}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^-?\d*\.?\d*(?:\s*€/\s*\$)?$"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Operation Cost of the Reference Case</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="k€/year"
                  name="operationCost"
                  value={values.operationCost}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*k€/year)?$"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Discount Rate *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="discountRate"
                  value={values.discountRate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*%)?$"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Project Lifetime *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="years"
                  name="projectLifetime"
                  value={values.projectLifetime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*(?:\s*years)?$"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Inflation Rate *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="inflationRate"
                  value={values.inflationRate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*%)?$"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Price Correction Factor of the Energy Purchased from the Main Grid (Annual Variation)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="%"
                  name="priceCorrectionFactor"
                  value={values.priceCorrectionFactor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^-?\d*\.?\d*(?:\s*%)?$"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Year *</Form.Label>
                <Form.Select
                  required
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  className={values.year ? 'has-value' : ''}
                >
                  <option value="">Please Select</option>
                  <option value="2017">2017</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
