import React, { useState, useContext } from "react";
import axios from "axios"; // ADD
import { useHistory } from "react-router-dom"; // ADD
import { Col, Row, Button, Alert } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ClientDataForm } from "../components/Forms-clientdata";
import { CommercialDataForm } from "../components/Forms-commercialdata";
import { ProjectFinancialsForm } from "../components/Forms-projectfinancials";
import { EmissionsCostForm } from "../components/Forms-emissionscost";
import { GlobalStateContext } from "../context/GlobalStateContext";
import { ThreeDots } from "react-loader-spinner"; // UPDATE
import "./Customer.css"; // ADD

export default () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // ADD
  const history = useHistory(); // ADD

  const handleCopyAddress = () => {
    setGlobalState(prevState => ({
      ...prevState,
      commercialAddressData: {
        ...prevState.commercialAddressData,
        address: globalState.clientAddressData.address,
        addressNumber: globalState.clientAddressData.addressNumber,
        city: globalState.clientAddressData.city,
        state: globalState.clientAddressData.state,
        zip: globalState.clientAddressData.zip
      }
    }));
  };

  const handleRunClick = async () => {
    // Check if all required fields are filled
    const allFieldsFilled =
        Object.values(globalState.clientAddressData || {}).every(field => field !== "") &&
        Object.values(globalState.commercialAddressData || {}).every(field => field !== "") &&
        Object.values(globalState.projectFinancials || {}).every(field => field !== "") &&
        Object.values(globalState.emissionsCost || {}).every(field => field !== "");

    if (!allFieldsFilled) {
      setErrorMessage("Please fill in all fields.");
      setSuccessMessage("");
      return;
    }

    setErrorMessage("");
    setLoading(true);
    console.log("Saved Global State:", JSON.stringify(globalState, null, 2));

    // Dynamically determine the API URL based on environment
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
    console.log("API_URL:", API_URL);

    try {
      // Send data to the server
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, globalState, {
        timeout: 5000, // 5 seconds timeout
      });
      console.log("Response received:", response);
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/microgrid/renewables"); // Navigate to the next page
        }, 3000);
      } else {
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      setErrorMessage("Server not responsive");
      setLoading(false);
    } finally {
      // Ensure the error message is cleared after 3 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Customer</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} xl={6}>
          <ClientDataForm
              values={globalState.clientAddressData}
              setValues={(values) => setGlobalState(prevState => ({ ...prevState, clientAddressData: values }))}
              disabled={loading}
          />
        </Col>
        <Col xs={12} xl={6}>
          <CommercialDataForm
            values={globalState.commercialAddressData}
            setValues={(values) => setGlobalState(prevState => ({ ...prevState, commercialAddressData: values }))}
            copyAddress={handleCopyAddress}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={6}>
          <ProjectFinancialsForm
              values={globalState.projectFinancials}
              setValues={(values) =>
                  setGlobalState((prevState) => ({
                    ...prevState,
                    projectFinancials: values,
                  }))
              }
          />
        </Col>
        <Col xs={12} xl={6}>
          <EmissionsCostForm
              values={globalState.emissionsCost}
              setValues={(values) => setGlobalState(prevState => ({ ...prevState, emissionsCost: values }))}
          />
        </Col>
      </Row>
    </>
  );
};