import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button, Modal, Alert } from "@themesberg/react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

import { PVTable } from "../components/Tables-pv";
import { FlexibilityRequestsTable } from "../components/Tables-flexRequests";

import { GridSellingForm } from "../components/Forms-gridSelling";
import { GridBuyingForm } from "../components/Forms-gridBuying";
import { PowerInformationForm } from "../components/Forms-powerInformation";
import { GridInformationForm } from "../components/Forms-gridInformation";

import { GlobalStateContext } from "../context/GlobalStateContext";
import { FormsFlexibilityRequests } from "../components/Forms-flexRequest";

import { usePV } from "../context/PVContext";
import { useBESS } from "../context/BESSContext";
import { useGENERATOR } from "../context/GENERATORContext";
import { useFlexibleRequests } from "../context/Requests_FlexibilityContext";

export default () => {
  // Contexts
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { addPvSystem } = usePV();
  const { addBessSystem } = useBESS();
  const { addGeneratorSystem } = useGENERATOR();
  const { flexibleRequests, addFlexibleRequest, updateFlexibleRequest } = useFlexibleRequests();

  const [validated_flexReq, setValidated_flexReq] = useState(false);
  const [validated_gridInformation, setValidated_gridInformation] = useState(false);
  const [validated_gridSelling, setValidated_gridSelling] = useState(false);
  const [validated_gridBuying, setValidated_gridBuying] = useState(false);
  const [validated_powerInformation, setValidated_powerInformation] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  
  const [values_flexReq, setValues_flexReq] = useState({
    name: '',
    days: [],
    months: [],
    scale: 'Daily',
    metric: '%',
    photoperiod: 'Day',
    price: '',
    power_variance: 'power_limited',
    measurement_ref: 'punto_conexion_red',
    K_r: '',
    P_r: '',
    request_type: '+'
  });

  const [disabledPriceOption, setDisabledPriceOption] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  // Set disabledPriceOption based on injectionToGrid value
  React.useEffect(() => {
    if (globalState.gridConnectionData?.injectionToGrid === 'Never' || !globalState.gridConnectionData?.injectionToGrid) {
      setDisabledPriceOption(true);
      if (!globalState.energySellingData?.priceOption) {
        setGlobalState(prevState => ({
          ...prevState,
          energySellingData: {
            ...prevState.energySellingData,
            priceOption: ''
          }
        }));
      }
    } else {
      setDisabledPriceOption(false);
    }
  }, [globalState.gridConnectionData?.injectionToGrid]);

  const handleShowModal = (title, data) => {
    setModalTitle(title);
    if (data) {
      setEditIndex(data.index);
      if (title === "Flexibility Request") {
        setValues_flexReq(data);
      }
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditIndex(null);
    setValues_flexReq({
      name: '',
      days: [],
      months: [],
      scale: 'Daily',
      metric: '%',
      photoperiod: 'Day',
      price: '',
      power_variance: 'power_limited',
      measurement_ref: 'punto_conexion_red',
      K_r: '',
      P_r: '',
      request_type: '+'
    });
  };

  const handleSave = () => {
    if (modalTitle === "Flexibility Request") {
      handleSave_flexReq();
    } 
  };

  const handleRunClick = async () => {
    // Check if all required fields are filled
    const requiredFieldsFilled = 
      globalState.gridConnectionData?.gridConnection !== "" &&
      globalState.gridConnectionData?.alwaysConnected !== "" &&
      globalState.gridConnectionData?.countryTariff !== "" &&
      globalState.gridConnectionData?.injectionToGrid !== "" &&
      globalState.powerInformationData?.tariff !== "" &&
      globalState.powerInformationData?.meter !== "" &&
      (
        globalState.energyBuyingData?.fixedPrice !== "" ||
        globalState.energyBuyingData?.period1 !== "" ||
        globalState.energyBuyingData?.annualPrice !== "" ||
        globalState.energyBuyingData?.profile !== ""
      );
  
    if (!requiredFieldsFilled) {
      setErrorMessage("Please fill in all required fields.");
      setSuccessMessage("");
      return;
    }
  
    setErrorMessage("");
    setLoading(true);
  
    console.log("Saved Global State:", JSON.stringify(globalState, null, 2));
  
    const dataToSend = {
      ...globalState,
      ...flexibleRequests
    };
  
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
    try {
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, dataToSend, {
        timeout: 5000,
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/constraints");
        }, 3000);
      } else {
        console.warn("Unexpected server response:", response.status);
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during server request:", error.message || error);
      setErrorMessage("Server not responsive");
      setLoading(false);
    }
  
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const handleSave_flexReq = () => {
    const form = document.querySelector("#flexibilityRequestsForm");
    if (form.checkValidity() === false) {
      setValidated_flexReq(true);
    } else {
      if (editIndex !== null) {
        updateFlexibleRequest(editIndex, values_flexReq);
      } else {
        addFlexibleRequest(values_flexReq);
      }
      console.log("Flexibility request saved: ", values_flexReq);
      setValues_flexReq({
        name: '',
        days: [],
        months: [],
        scale: 'Daily',
        metric: '%',
        photoperiod: 'Day',
        price: '',
        power_variance: 'power_limited',
        measurement_ref: 'punto_conexion_red',
        K_r: '',
        P_r: '',
        request_type: '+'
      });
      setShowModal(false);
      setValidated_flexReq(false);
      setEditIndex(null);
    }
  };

  return (
    <>
      {loading && ( 
        <div className="loading-overlay"> 
          <ThreeDots color="#00BFFF" height={80} width={80} /> 
        </div> 
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Grid Connection</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="align-items-start mt-4 mb-4">
        <Col>
          <GridInformationForm 
            values={globalState.gridConnectionData || {}} 
            setValues={(data) => setGlobalState(prevState => ({ ...prevState, gridConnectionData: data }))} 
            validated={validated_gridInformation} 
          />
        </Col>
        <Col>
          <PowerInformationForm 
            values={globalState.powerInformationData || {}} 
            setValues={(data) => setGlobalState(prevState => ({ ...prevState, powerInformationData: data }))} 
            validated={validated_powerInformation} 
          />
        </Col>
      </Row>

      <Row className="align-items-start mt-4 mb-4">
        <Col>
          <GridSellingForm 
            values={globalState.energySellingData || {}} 
            setValues={(data) => setGlobalState(prevState => ({ ...prevState, energySellingData: data }))} 
            validated={validated_gridSelling} 
            disabledPriceOption={disabledPriceOption} 
          />
        </Col>
        <Col>
          <GridBuyingForm 
            values={globalState.energyBuyingData || {}} 
            setValues={(data) => setGlobalState(prevState => ({ ...prevState, energyBuyingData: data }))} 
            validated={validated_gridBuying} 
          />
        </Col>
      </Row>

      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Flexibility Requests</h4>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            type="button"
            className="text-dark me-2"
            onClick={() => handleShowModal("Flexibility Request")}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Button>
        </Col>
      </Row>
      <FlexibilityRequestsTable className="mb-4" handleShowModal={handleShowModal} />

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title className="h6">{modalTitle}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
            <FormsFlexibilityRequests values={values_flexReq} setValues={setValues_flexReq} validated={validated_flexReq} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};