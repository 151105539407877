import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faTable, faMapPin, faFileAlt, faRocket } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Button, Accordion } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import eRootsLogo from "../assets/img/eRoots_Banner_Light_Logotype.png";  // Import the PNG logo
import { faDashcube } from "@fortawesome/free-brands-svg-icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";



    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", blockButtons } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
        <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
          <Nav.Link {...linkProps} target={target} className={classNames} disabled={blockButtons}>
                <span>
                    {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                  {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
                  <span className="sidebar-text">{title}</span>
                </span>
          </Nav.Link>
        </Nav.Item>
    );
  };


  return (
    <>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            {/* Button with the PNG logo */}
            <Button variant="link" className="d-flex align-items-center mb-3">
              <Image src={eRootsLogo} height={40} alt="eRoots Logo" />  {/* Display the PNG logo here */}
            </Button>

            <hr className="custom-divider" />
            <Nav className="flex-column pt-3 pt-md-0">
              {/* <NavItem title="Dashboard" link={Routes.DashboardOverview.path} image={faDashcube} /> */}
              <NavItem title="Customer" icon={faBoxOpen} link={Routes.Customer.path} blockButtons={props.blockButtons} />
              <CollapsableNavItem eventKey="microgrid/" title="Microgrid" icon={faTable}>
                <NavItem title="Assets" link={Routes.Renewables.path} blockButtons={props.blockButtons} />
                <NavItem title="Loads" link={Routes.Loads.path} blockButtons={props.blockButtons} />
                <NavItem title="Flexible Loads" link={Routes.FlexibleLoads.path} blockButtons={props.blockButtons} />
              </CollapsableNavItem>
              <NavItem title="Grid Connection" icon={faMapPin} link={Routes.GridConnection.path} blockButtons={props.blockButtons} />
              <NavItem title="Constraints" icon={faFileAlt} link={Routes.Constraints.path} blockButtons={props.blockButtons} />
              <NavItem title="Optimization" icon={faRocket} link={Routes.Optimization.path} blockButtons={props.blockButtons} />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
