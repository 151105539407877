import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import "./Forms.css";

export const CommercialDataForm = ({ values, setValues, copyAddress}) => {
  // const [values, setValues] = useState({
  //   firstName: "",
  //   cif: "",
  //   email: "",
  //   phone: "",
  //   address: "",
  //   addressNumber: "",
  //   city: "",
  //   state: "",
  //   zip: "",
  // });
  //
  // useEffect(() => {
  //   const savedValues = JSON.parse(localStorage.getItem("commercialData")) || {};
  //   setValues(savedValues);
  // }, []);
  //
  // useEffect(() => {
  //   localStorage.setItem("commercialData", JSON.stringify(values));
  // }, [values]);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //
  //   // Update state directly
  //   setValues((prevValues) => {
  //     const newValues = {
  //       ...prevValues,
  //       [name]: value,
  //     };
  //
  //     // If address-related fields change, update the parent component
  //     if (["firstName", "lastName", "cif", "phone", "email", "address", "addressNumber", "city", "state", "zip", ].includes(name)) {
  //       setCommercialData({
  //         firstName: newValues.firstName,
  //         cif: newValues.cif,
  //         phone: newValues.phone,
  //         email: newValues.email,
  //         address: newValues.address,
  //         addressNumber: newValues.addressNumber,
  //         city: newValues.city,
  //         state: newValues.state,
  //         zip: newValues.zip,
  //       });
  //     }
  //
  //     return newValues;
  //   });
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Only allow numbers and decimal points in the input
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Perform regex check to ensure the value is a valid number or empty (for phone and addressNumber fields)
    if (["phone", "addressNumber"].includes(name)) {
      const isValidNumber =
        value === "" || /^\s*(?=.*[0-9])\d*(?:[.,]\d*)?\s*$/.test(value);

      if (!isValidNumber) {
        setValues({
          ...values,
          [name]: "",
        });
      }
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Commercial Data</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="eRoots Analytics SL"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  pattern=".+"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="cif">
                <Form.Label>CIF</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Bxxxxxxxx"
                  name="cif"
                  value={ values.cif}
                  onChange={handleChange}
                  pattern="[Bb][0-9]{8}"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="name@company.com"
                  name="email"
                  value={ values.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="9xx xxx xxx"
                  name="phone"
                  value={ values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  pattern="[0-9]{9}"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-4">
            <Button variant="primary" type="button" onClick={copyAddress}>
              Copy Address
            </Button>
          </div>
          <Row className="mt-4">
            <Col sm={9} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Av. Diagonal, 647"
                  name="address"
                  value={ values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  pattern=".+"
                />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="No."
                  name="addressNumber"
                  value={ values.addressNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  pattern="[0-9]+"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="City"
                  name="city"
                  value={ values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  pattern=".+"
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="state">
                <Form.Label>Territory / Province</Form.Label>
                <Form.Select
                  required
                  name="state"
                  value={ values.state}
                  onChange={handleChange}
                  className={( values.state) ? 'has-value' : ''}
                >
                  <option value="">Please Select</option>
                  <option value="Peninsula">Peninsula</option>
                  <option value="Canarias">Canarias</option>
                  <option value="Baleares">Baleares</option>
                  <option value="Ceuta">Ceuta</option>
                  <option value="Melilla">Melilla</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  placeholder="ZIP"
                  name="zip"
                  value={ values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  pattern="[0-9]{5}"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
