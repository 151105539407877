import React, { useState, useContext } from "react";
import { Container, Row, Col, Button, Alert } from "@themesberg/react-bootstrap";
import EconomicConstraintsForm from "../components/EconomicConstraintsForm";
import RenewableShareConstraintsForm from "../components/RenewableShareConstraintsForm";
import { useHistory } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { GlobalStateContext } from "../context/GlobalStateContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Constraints = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleRunClick = async () => {
    const requiredFieldsFilled =
      // Uncomment these lines if these fields are required
      // globalState.economicConstraints.roiOperator !== '' &&
      // globalState.economicConstraints.roiValue !== '' &&
      // globalState.economicConstraints.paybackOperator !== '' &&
      // globalState.economicConstraints.paybackValue !== '' &&
      // globalState.economicConstraints.investmentOperator !== '' &&
      // globalState.economicConstraints.investmentValue !== '' &&
      globalState.renewableShareConstraints.renewableShare !== '';
  
    if (!requiredFieldsFilled) {
      setErrorMessage("Please fill in all required fields.");
      setSuccessMessage("");
      return;
    }
  
    setErrorMessage("");
    setLoading(true);
  
    console.log('Saved Global State:', JSON.stringify(globalState, null, 2));
  
    // Dynamically determine the API URL based on environment
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:5000";
    // const API_URL = "http://34.175.205.14";

    try {
      const response = await axios.post(`${API_URL}/load_chamber_any_json`, globalState, {
        timeout: 5000, // 5 seconds timeout
      });
  
      if (response.status === 200) {
        console.log("Data successfully sent to the server.");
        setSuccessMessage("Data successfully saved.");
        setTimeout(() => {
          setSuccessMessage("");
          setLoading(false);
          history.push("/optimization"); // Navigate to the next page
        }, 3000);
      } else {
        setErrorMessage("Server not responsive");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      setErrorMessage("Server not responsive");
      setLoading(false);
    } finally {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <Container>
      {loading && (
        <div className="loading-overlay">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      )}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
      <Row className="align-items-center mt-4 mb-4">
        <Col>
          <h4>Constraints</h4>
        </Col>
        <Col className="text-right">
          <Button
            variant="success"
            type="button"
            className="me-2"
            onClick={handleRunClick}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
            <span>Next</span>
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="align-items-start mb-4">
        <Col md={6}>
          <EconomicConstraintsForm 
            values={globalState.economicConstraints} 
            setValues={(values) => setGlobalState(prev => ({ ...prev, economicConstraints: values }))} 
          />
        </Col>
        <Col md={6}>
          <RenewableShareConstraintsForm 
            values={globalState.renewableShareConstraints} 
            setValues={(values) => setGlobalState(prev => ({ ...prev, renewableShareConstraints: values }))} 
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Constraints;