import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const CriticalLoadContext = createContext([]);

// Provider component
export const CriticalLoadProvider = ({ children }) => {
  const [criticalLoads, setCriticalLoads] = useState([]);

  const addCriticalLoad = (criticalLoad) => {
    setCriticalLoads([...criticalLoads, criticalLoad]);
  };

  const deleteCriticalLoad = (index) => {
    setCriticalLoads(criticalLoads.filter((_, i) => i !== index));
  };

  const updateCriticalLoad = (index, updatedCriticalLoad) => {
    setCriticalLoads(criticalLoads.map((criticalLoad, i) => (i === index ? updatedCriticalLoad : criticalLoad)));
  };

  return (
    <CriticalLoadContext.Provider value={{ criticalLoads, setCriticalLoads, addCriticalLoad, deleteCriticalLoad, updateCriticalLoad }}>
      {children}
    </CriticalLoadContext.Provider>
  );
};

// Custom hook to use the Critical Load context
export const useCriticalLoad = () => {
  return useContext(CriticalLoadContext);
};