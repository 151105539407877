import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleLeft, faChartBar} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Container, InputGroup, Form, Button } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalStateContext } from "../context/GlobalStateContext";
import { Routes } from "../routes";
import BannerImage from "../assets/img/eRoots_Google_Workspace-01.png";

export default () => {
  const { globalState } = useContext(GlobalStateContext); // Use useContext to access globalState
  const email = globalState.clientAddressData?.email || "No Email Provided";

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* Add Banner Image */}
          <Row className="justify-content-center mb-4">
            <Col xs={12} className="d-flex justify-content-center">
              <img src={BannerImage} alt="Banner" className="img-fluid" style={{ maxHeight: '30px' }} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Your request has been successfully received</h3>
                  <p className="mt-2">You will receive a confirmation email at <strong>{email}</strong> in a few minutes.</p>
                </div>
                <Form className="mt-4">
                  <Form.Group id="requestId" className="mb-4">
                    <Form.Label>Request ID</Form.Label>
                    <InputGroup>
                      <Form.Control readOnly value={globalState.request_id || "No Request ID"} />
                    </InputGroup>
                  </Form.Group>
                </Form>
                <Row>
                  <Col>
                    <div className="text-center">
                      <Button as={Link} to={Routes.Start.path} variant="primary">
                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Return to homepage
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-center">
                    <Button as={Link} to={Routes.Results.path} variant="success">
                      <FontAwesomeIcon icon={faChartBar} className="me-2" /> View Demo Results
                    </Button>
                    </div>
                  </Col>
                </Row>


              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};