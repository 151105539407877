import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const ReducibleLoadContext = createContext([]);

// Provider component
export const ReducibleLoadProvider = ({ children }) => {
  const [reducibleLoads, setReducibleLoads] = useState([]);

  const addReducibleLoad = (reducibleLoad) => {
    setReducibleLoads([...reducibleLoads, reducibleLoad]);
  };

  const deleteReducibleLoad = (index) => {
    setReducibleLoads(reducibleLoads.filter((_, i) => i !== index));
  };

  const updateReducibleLoad = (index, updatedReducibleLoad) => {
    setReducibleLoads(reducibleLoads.map((reducibleLoad, i) => (i === index ? updatedReducibleLoad : reducibleLoad)));
  };

  return (
    <ReducibleLoadContext.Provider value={{ reducibleLoads, setReducibleLoads, addReducibleLoad, deleteReducibleLoad, updateReducibleLoad }}>
      {children}
    </ReducibleLoadContext.Provider>
  );
};

// Custom hook to use the Reducible Load context
export const useReducibleLoad = () => {
  return useContext(ReducibleLoadContext);
};