import React, { createContext, useState, useContext } from 'react';

const GENERATORContext = createContext();

export const GENERATORProvider = ({ children }) => {
  const [generatorSystems, setGeneratorSystems] = useState([]);

  const addGeneratorSystem = (generatorSystem) => {
    setGeneratorSystems([...generatorSystems, generatorSystem]);
  };

  const deleteGeneratorSystem = (index) => {
    setGeneratorSystems(generatorSystems.filter((_, i) => i !== index));
  };

  const updateGeneratorSystem = (index, updatedGeneratorSystem) => {
    setGeneratorSystems(generatorSystems.map((generatorSystem, i) => (i === index ? updatedGeneratorSystem : generatorSystem)));
  };

  return (
    <GENERATORContext.Provider value={{ generatorSystems, setGeneratorSystems, addGeneratorSystem, deleteGeneratorSystem, updateGeneratorSystem }}>
      {children}
    </GENERATORContext.Provider>
  );
};

export const useGENERATOR = () => {
  return useContext(GENERATORContext);
};