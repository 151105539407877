import sampleCaseJson from '../request_jsons/NJEF33AG_2025-03-31-02-39-25.json';

export const load_globalState = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.globalState;
}; 

export const load_evData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.evData;
}; 

export const load_timeSeriesData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.timeSeriesData;
}; 

export const load_pvData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.pvSystems;
}; 

export const load_bessData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.bessSystems;
}; 

export const load_generatorData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.generatorSystems;
}; 

export const load_criticalLoadData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.criticalLoads;
};

export const load_reducibleLoadData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.reducibleLoads;
};

export const load_disconnectableLoadData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.disconnectableLoads;
};

export const load_powerShiftableLoadData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.powerShiftableLoads;
};

export const load_timeShiftableLoadData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.timeShiftableLoads;
};

export const load_FlexibleRequestData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.flexibleRequests;
};

export const load_CasesData = () => {
  // The JSON file has a globalState property that contains all the form data
  return sampleCaseJson.cases;
};







































