import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const CasesContext = createContext([]);

// Provider component
export const CasesProvider = ({ children }) => {
  const [cases, setCases] = useState([]);

  const addCase = (newCase) => {
    setCases([...cases, newCase]);
  };

  const deleteCase = (index) => {
    setCases(cases.filter((_, i) => i !== index));
  };

  const updateCase = (index, updatedCase) => {
    setCases(cases.map((caseItem, i) => (i === index ? updatedCase : caseItem)));
  };

  return (
    <CasesContext.Provider value={{ cases, setCases, addCase, deleteCase, updateCase }}>
      {children}
    </CasesContext.Provider>
  );
};

// Custom hook to use the Cases context
export const useCases = () => {
  return useContext(CasesContext);
};