import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const DisconnectableLoadContext = createContext([]);

// Provider component
export const DisconnectableLoadProvider = ({ children }) => {
  const [disconnectableLoads, setDisconnectableLoads] = useState([]);

  const addDisconnectableLoad = (disconnectableLoad) => {
    setDisconnectableLoads([...disconnectableLoads, disconnectableLoad]);
  };

  const deleteDisconnectableLoad = (index) => {
    setDisconnectableLoads(disconnectableLoads.filter((_, i) => i !== index));
  };

  const updateDisconnectableLoad = (index, updatedDisconnectableLoad) => {
    setDisconnectableLoads(disconnectableLoads.map((disconnectableLoad, i) => (i === index ? updatedDisconnectableLoad : disconnectableLoad)));
  };

  return (
    <DisconnectableLoadContext.Provider value={{ disconnectableLoads, setDisconnectableLoads, addDisconnectableLoad, deleteDisconnectableLoad, updateDisconnectableLoad }}>
      {children}
    </DisconnectableLoadContext.Provider>
  );
};

// Custom hook to use the Disconnectable Load context
export const useDisconnectableLoad = () => {
  return useContext(DisconnectableLoadContext);
};