import React from 'react';
import Plot from 'react-plotly.js';
import { useTimeSeries } from '../context/TimeSeriesContext'; // Import the custom hook
import { useEV } from '../context/EVContext'; // Import the custom hook

export const ChartTimeSeries = () => {
  const { timeSeriesData } = useTimeSeries(); // Use the time series context
  const { evData } = useEV(); // Use the EV context

  // Process the baseline string to generate data
  const processBaselineData = (baseline) => {
    if (!baseline) {
      return [];
    }
    return baseline
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '')
      .map(value => parseFloat(value.replace(',', '.'))); // Replace commas with dots
  };

  // Process the EV time series string to generate data
  const processEVData = (timeSeries) => {
    if (!timeSeries) {
      return [];
    }
    return timeSeries
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '')
      .map(value => parseFloat(value.replace(',', '.'))); // Replace commas with dots
  };

  const baselineData = processBaselineData(timeSeriesData.baseline);
  const evTimeSeriesData = processEVData(evData.timeSeries);
  const isEVSmartCharging = evData.smartCharging === '1';

  const plotData = [
    {
      x: Array.from({ length: baselineData.length }, (_, i) => i + 1),
      y: baselineData,
      type: 'scatter',
      mode: 'lines',
      name: 'Baseline',
      line: { color: '#1f77b4' } // Match the color of Series 1 in ProfileChart.css
    },
    // Conditionally include the EV dataset
    ...(isEVSmartCharging ? [] : [{
      x: Array.from({ length: evTimeSeriesData.length }, (_, i) => i + 1),
      y: evTimeSeriesData,
      type: 'scatter',
      mode: 'lines',
      name: 'Electric Vehicle',
      line: { color: '#ff7f0e' } // Match the color of Series 2 in ProfileChart.css
    }])
  ];

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Plot
        data={plotData}
        layout={{
          autosize: true,
          margin: {
            l: 50, // left margin
            r: 50, // right margin
            b: 50, // bottom margin
            t: 25, // top margin reduced
            pad: 4
          },
          xaxis: {
            title: 'Hour',
            showgrid: true,
            zeroline: false
          },
          yaxis: {
            title: 'Value',
            showline: false,
            range: [0, 100] // Ensure the y-axis covers the full range of values
          },
          legend: {
            x: 1,
            y: 0,
            xanchor: 'right',
            yanchor: 'bottom',
            orientation: 'h', // Set legend to horizontal orientation if you prefer
          }
        }}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
        config={{
          scrollZoom: true,
          responsive: true
        }}
      />
    </div>
  );
};

// Generate hourly data for an entire year
const generateHourlyData = () => {
  const data = [];
  for (let i = 0; i < 8760; i++) {
    data.push(Math.floor(Math.random() * 100)); // Random data between 0 and 100
  }
  return data;
};