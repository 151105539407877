import React, { createContext, useState, useContext } from 'react';

const BESSContext = createContext();

export const BESSProvider = ({ children }) => {
  const [bessSystems, setBessSystems] = useState([]);

  const addBessSystem = (bessSystem) => {
    setBessSystems([...bessSystems, bessSystem]);
  };

  const deleteBessSystem = (index) => {
    setBessSystems(bessSystems.filter((_, i) => i !== index));
  };

  const updateBessSystem = (index, updatedBessSystem) => {
    setBessSystems(bessSystems.map((bessSystem, i) => (i === index ? updatedBessSystem : bessSystem)));
  };

  return (
    <BESSContext.Provider value={{ bessSystems, setBessSystems, addBessSystem, deleteBessSystem, updateBessSystem }}>
      {children}
    </BESSContext.Provider>
  );
};

export const useBESS = () => {
  return useContext(BESSContext);
};