import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faAngleLeft, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup, Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../routes';
import {
    CounterWidget,
    CircleChartWidget,
    BarChartWidget,
    TeamMembersWidget,
    ProgressTrackWidget,
    RankingWidget,
    SalesValueWidget,
    SalesValueWidgetPhone,
    AcquisitionWidget,
    MicrogridEconomicWidget,
    EnergySourceWidget
} from "../components/Widgets";
import { PageVisitsTable } from "../components/Tables";
import { trafficShares, totalOrders } from "../data/charts";
import Sidebar from "../components/Sidebar";

export default () => {
    return (
        <div className="d-flex">
            <Sidebar blockButtons={true} /> {/* Pass a prop to block buttons */}
            <main className="content">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="text-center">
                    <Button as={Link} to={Routes.Start.path} variant="primary">
                        <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Return to homepage
                    </Button>
                </div>

                <ButtonGroup>
                    <Button variant="outline-primary" size="sm">Share</Button>
                    <Button variant="outline-primary" size="sm">Export</Button>
                </ButtonGroup>
            </div>

            <Alert variant="info" className="mb-4">
                <strong>Demo Dashboard Preview</strong><br />
                The data shown here is for illustrative purposes only and does not reflect your specific case analysis. Your actual results are currently being processed and will be delivered to your registered email address.
            </Alert>

            <Row className="justify-content-md-center">
                {/* <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}
                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CounterWidget
                        category="Cumulative"
                        title="Cash Flow: 345 k€"
                        period="Jan 2018 - Jan 2019"
                        percentage={18.2}
                        icon={faChartLine}
                        iconColor="shape-secondary"
                    />
                </Col>

                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CounterWidget
                        category="Grid Operation"
                        title="Cost: 82.62 k€"
                        period="Jan 2018 - Jan 2019"
                        percentage={28.4}
                        icon={faCashRegister}
                        iconColor="shape-tertiary"
                    />
                </Col>

                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CircleChartWidget
                        title="Sizing"
                        data={trafficShares} />
                </Col>
            </Row>

            <Row>
                <Col xs={12} xl={12} className="mb-4">
                    <Row>
                        <Col xs={12} xl={8} className="mb-4">
                            <Row>
                                <Col xs={12} className="mb-4">
                                    <PageVisitsTable />
                                </Col>

                                {/*<Col xs={12} lg={6} className="mb-4">*/}
                                {/*    <TeamMembersWidget />*/}
                                {/*</Col>*/}

                                <Row>
                                    <Col xs={12} lg={6} className="mb-4">
                                        < MicrogridEconomicWidget/>
                                    </Col>

                                    <Col>
                                        <Row xs={12} className="px-0">
                                            < EnergySourceWidget/>
                                        </Row>

                                    </Col>

                                </Row>




                            </Row>
                        </Col>

                        <Col xs={12} xl={4}>
                            <Row>
                                <Col xs={12} className="mb-4">

                                    <Row>
                                        <BarChartWidget
                                            title="Total Energy Transactions"
                                            value={"452 kWh"}
                                            percentage={18.2}
                                            data={totalOrders} />
                                        <ProgressTrackWidget />
                                    </Row>

                                </Col>

                                {/*<Col xs={12} className="px-0 mb-4">*/}
                                {/*   */}
                                {/*</Col>*/}

                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </main>
        </div>
    );
};
