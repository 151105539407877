import React, { createContext, useState, useContext } from "react";

// Create a context with default value
const FlexibleRequestsContext = createContext([]);

// Provider component
export const FlexibleRequestsProvider = ({ children }) => {
  const [flexibleRequests, setFlexibleRequests] = useState([]);

  const addFlexibleRequest = (flexibleRequest) => {
    setFlexibleRequests([...flexibleRequests, flexibleRequest]);
  };

  const deleteFlexibleRequest = (index) => {
    setFlexibleRequests(flexibleRequests.filter((_, i) => i !== index));
  };

  const updateFlexibleRequest = (index, updatedFlexibleRequest) => {
    setFlexibleRequests(flexibleRequests.map((flexibleRequest, i) => (i === index ? updatedFlexibleRequest : flexibleRequest)));
  };

  return (
    <FlexibleRequestsContext.Provider value={{ flexibleRequests, setFlexibleRequests, addFlexibleRequest, deleteFlexibleRequest, updateFlexibleRequest }}>
      {children}
    </FlexibleRequestsContext.Provider>
  );
};

// Custom hook to use the Flexible Requests context
export const useFlexibleRequests = () => {
  return useContext(FlexibleRequestsContext);
};