import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';
import "./Forms.css";

export const EmissionsCostForm = ({ values, setValues }) => {
  // const [values, setValues] = useState({
  //   costCO: '',
  //   costHC: '',
  //   costParticles: '',
  //   costNOx: '',
  //   costCO2: ''
  // });
  //
  // useEffect(() => {
  //   const savedValues = JSON.parse(localStorage.getItem("emissionsData")) || {};
  //   setValues(savedValues);
  // }, []);
  //
  // useEffect(() => {
  //   localStorage.setItem("emissionsData", JSON.stringify(values));
  // }, [values]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Only allow numbers and decimal points in the input
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleFocus = (event) => {
    const { name, value } = event.target;
    if (value) {
      // Split on first space and keep only the number part
      const numericValue = value.split(' ')[0];
      setValues(prevValues => ({
        ...prevValues,
        [name]: numericValue
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value) {
      // Add €/kg to all emission cost fields if not already present
      const formattedValue = value.includes('€/kg') ? value : `${value} €/kg`;
      setValues({
        ...values,
        [name]: formattedValue
      });

      // Update parent component with the formatted value
      // if (setEmissionsData) {
      //   setEmissionsData(prevData => ({
      //     ...prevData,
      //     [name]: formattedValue
      //   }));
      // }
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Emissions Cost</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost CO *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costCO"
                  value={values.costCO}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*€/kg)?$"
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost HC *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costHC"
                  value={values.costHC}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*€/kg)?$"
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost Particles *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costParticles"
                  value={values.costParticles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*€/kg)?$"
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost NOx *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costNOx"
                  value={values.costNOx}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*€/kg)?$"
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Cost CO2 *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="€/kg"
                  name="costCO2"
                  value={values.costCO2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  pattern="^\d*\.?\d*(?:\s*€/kg)?$"
                />
                <Form.Control.Feedback type="invalid">Please enter a valid number.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};