import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Container, Button, Modal } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { GlobalStateContext } from "../context/GlobalStateContext";
import { useTimeSeries } from "../context/TimeSeriesContext";
import { useEV } from "../context/EVContext";
import { usePV } from "../context/PVContext";
import { useBESS } from "../context/BESSContext";
import { useGENERATOR } from "../context/GENERATORContext";
import { useCriticalLoad } from "../context/Loads_CriticalContext";
import { useDisconnectableLoad } from "../context/Loads_DisconnectableContext";
import { useReducibleLoad } from "../context/Loads_ReducibleContext";
import { usePowerShiftableLoad } from "../context/Loads_PowerShiftableContext";
import { useTimeShiftableLoad } from "../context/Loads_TimeShiftableContext";
import { useFlexibleRequests } from "../context/Requests_FlexibilityContext";
import { useCases } from "../context/CasesContext";
import { Routes } from "../routes";
import BannerImage from "../assets/img/eRoots_Google_Workspace-01.png";
import { load_globalState, load_evData, load_timeSeriesData, load_pvData, load_bessData, load_generatorData,
  load_criticalLoadData, load_disconnectableLoadData, load_powerShiftableLoadData, load_timeShiftableLoadData, load_reducibleLoadData,
  load_FlexibleRequestData, load_CasesData } from "../utils/loadSampleCase";

export default () => {
  const { setGlobalState } = useContext(GlobalStateContext);
  const { updateBaseline } = useTimeSeries();
  const { updateEVData } = useEV();
  const { setPvSystems } = usePV();
  const { setBessSystems } = useBESS();
  const { setGeneratorSystems } = useGENERATOR();
  const { setCriticalLoads } = useCriticalLoad();
  const { setDisconnectableLoads } = useDisconnectableLoad();
  const { setReducibleLoads } = useReducibleLoad();
  const { setPowerShiftableLoads } = usePowerShiftableLoad();
  const { setTimeShiftableLoads } = useTimeShiftableLoad();
  const { setFlexibleRequests } = useFlexibleRequests();
  const { setCases } = useCases();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSampleCase = () => {
    const sampleData = load_globalState();
    const evData = load_evData();
    const timeSeriesData = load_timeSeriesData();
    const pvData = load_pvData();
    const bessData = load_bessData();
    const generatorData = load_generatorData();
    const criticalLoadData = load_criticalLoadData();
    const disconnectableLoadData = load_disconnectableLoadData();
    const powerShiftableLoadData = load_powerShiftableLoadData();
    const timeShiftableLoadData = load_timeShiftableLoadData();
    const reducibleLoadData = load_reducibleLoadData();
    const flexibleRequestData = load_FlexibleRequestData();
    const casesData = load_CasesData();
    
    // Update Time Series Context
    updateBaseline(timeSeriesData.baseline);

    // Update EV Context
    Object.entries(evData).forEach(([key, value]) => {
      updateEVData(key, value);
    });

    // Update PV Context
    setPvSystems(pvData);

    // Update BESS Context
    setBessSystems(bessData);

    // Update GENERATOR Context
    setGeneratorSystems(generatorData);

    // Update Load Contexts
    setCriticalLoads(criticalLoadData);
    setDisconnectableLoads(disconnectableLoadData);
    setPowerShiftableLoads(powerShiftableLoadData);
    setTimeShiftableLoads(timeShiftableLoadData);
    setReducibleLoads(reducibleLoadData);

    // Update Flexible Requests Context
    setFlexibleRequests(flexibleRequestData);

    // Update Cases Context
    setCases(casesData);
    
    // Update Global State
    setGlobalState(prevState => ({
      ...prevState,
      // Client Address Data
      clientAddressData: {
        firstName: sampleData?.clientAddressData?.firstName || '',
        lastName: sampleData?.clientAddressData?.lastName || '',
        cif: sampleData?.clientAddressData?.cif || '',
        phone: sampleData?.clientAddressData?.phone || '',
        email: sampleData?.clientAddressData?.email || '',
        address: sampleData?.clientAddressData?.address || '',
        addressNumber: sampleData?.clientAddressData?.addressNumber || '',
        city: sampleData?.clientAddressData?.city || '',
        state: sampleData?.clientAddressData?.state || '',
        zip: sampleData?.clientAddressData?.zip || ''
      },
      // Commercial Address Data
      commercialAddressData: {
        firstName: sampleData?.commercialAddressData?.firstName || '',
        cif: sampleData?.commercialAddressData?.cif || '',
        phone: sampleData?.commercialAddressData?.phone || '',
        email: sampleData?.commercialAddressData?.email || '',
        address: sampleData?.commercialAddressData?.address || '',
        addressNumber: sampleData?.commercialAddressData?.addressNumber || '',
        city: sampleData?.commercialAddressData?.city || '',
        state: sampleData?.commercialAddressData?.state || '',
        zip: sampleData?.commercialAddressData?.zip || ''
      },
      // Emissions Cost Data
      emissionsCost: {
        costCO: sampleData?.emissionsCost?.costCO || '',
        costHC: sampleData?.emissionsCost?.costHC || '',
        costParticles: sampleData?.emissionsCost?.costParticles || '',
        costNOx: sampleData?.emissionsCost?.costNOx || '',
        costCO2: sampleData?.emissionsCost?.costCO2 || ''
      },
      // Project Financials Data
      projectFinancials: {
        cashChange: sampleData?.projectFinancials?.cashChange || '',
        operationCost: sampleData?.projectFinancials?.operationCost || '',
        discountRate: sampleData?.projectFinancials?.discountRate || '',
        projectLifetime: sampleData?.projectFinancials?.projectLifetime || '',
        inflationRate: sampleData?.projectFinancials?.inflationRate || '',
        priceCorrectionFactor: sampleData?.projectFinancials?.priceCorrectionFactor || '',
        year: sampleData?.projectFinancials?.year || ''
      },
      // Grid Connection Data
      gridConnectionData: {
        gridConnection: sampleData?.gridConnectionData?.gridConnection || '',
        alwaysConnected: sampleData?.gridConnectionData?.alwaysConnected || '',
        countryTariff: sampleData?.gridConnectionData?.countryTariff || '',
        injectionToGrid: sampleData?.gridConnectionData?.injectionToGrid || ''
      },
      // Energy Buying Data
      energyBuyingData: {
        priceOption: sampleData?.energyBuyingData?.priceOption || '',
        fixedPrice: sampleData?.energyBuyingData?.fixedPrice || '',
        period1: sampleData?.energyBuyingData?.period1 || '',
        period2: sampleData?.energyBuyingData?.period2 || '',
        period3: sampleData?.energyBuyingData?.period3 || '',
        period4: sampleData?.energyBuyingData?.period4 || '',
        period5: sampleData?.energyBuyingData?.period5 || '',
        period6: sampleData?.energyBuyingData?.period6 || '',
        annualPrice: sampleData?.energyBuyingData?.annualPrice || '',
        profile: sampleData?.energyBuyingData?.profile || ''
      },
      // Power Information Data
      powerInformationData: {
        tariff: sampleData?.powerInformationData?.tariff || '',
        meter: sampleData?.powerInformationData?.meter || '',
        period1: sampleData?.powerInformationData?.period1 || '',
        period2: sampleData?.powerInformationData?.period2 || '',
        period3: sampleData?.powerInformationData?.period3 || '',
        period4: sampleData?.powerInformationData?.period4 || '',
        period5: sampleData?.powerInformationData?.period5 || '',
        period6: sampleData?.powerInformationData?.period6 || ''
      },
      // Energy Selling Data
      energySellingData: {
        priceOption: sampleData?.energySellingData?.priceOption || '',
        fixedPrice: sampleData?.energySellingData?.fixedPrice || '',
        period1: sampleData?.energySellingData?.period1 || '',
        period2: sampleData?.energySellingData?.period2 || '',
        period3: sampleData?.energySellingData?.period3 || '',
        period4: sampleData?.energySellingData?.period4 || '',
        period5: sampleData?.energySellingData?.period5 || '',
        period6: sampleData?.energySellingData?.period6 || '',
        annualPrice: sampleData?.energySellingData?.annualPrice || '',
        profile: sampleData?.energySellingData?.profile || ''
      },
      // Economic Constraints Data
      economicConstraints: {
        roiOperator: sampleData?.economicConstraints?.roiOperator || '',
        roiValue: sampleData?.economicConstraints?.roiValue || '',
        paybackOperator: sampleData?.economicConstraints?.paybackOperator || '',
        paybackValue: sampleData?.economicConstraints?.paybackValue || '',
        investmentOperator: sampleData?.economicConstraints?.investmentOperator || '',
        investmentValue: sampleData?.economicConstraints?.investmentValue || ''
      },
      // Renewable Share Constraints Data
      renewableShareConstraints: {
        renewableShare: sampleData?.renewableShareConstraints?.renewableShare || ''
      }
    }));

    handleClose();
    history.push('/customer');
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* Add Banner Image */}
          <Row className="justify-content-center mb-4">
            <Col xs={12} className="d-flex justify-content-center">
              <img src={BannerImage} alt="Banner" className="img-fluid" style={{ maxHeight: '30px' }} />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Welcome!</h3>
                  <p className="mt-2">This is the Microgrid Sizing and Optimization Tool. This tool will take you through step-by-step to understand your case.</p>
                </div>
                <div className="text-center">
                  <Button onClick={handleShow} variant="primary">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Click here to begin!
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Get Started</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p className="mb-4">Select how you'd like to proceed with your optimization</p>
            <div className="d-flex justify-content-center gap-3">
              <Button as={Link} to="/customer" variant="primary" onClick={handleClose}>
                Create New Case
              </Button>
              <Button variant="secondary" onClick={handleSampleCase}>
                Open Demo Case
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};